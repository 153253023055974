import React from "react";


const AdminDashboard = () => {
//   const [vacancyCount, setVacancyCount] = useState(0);

//   useEffect(() => {
//     const fetchVacancyCount = async () => {
//       try {
//         const response = await axios.get("http://localhost:8000/api/vacancies/count");
//         setVacancyCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching vacancy count:", error);
//       }
//     };

//     fetchVacancyCount();
//   }, []);

  return (
    <div className="container mt-4">
       
    </div>
   );
};

export default AdminDashboard;
